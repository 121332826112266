
import { defineComponent } from "vue";
import { ContentLoader } from "vue-content-loader";

export default defineComponent({
  name: "MdTableCellLoader",
  components: {
    ContentLoader
  },
  props: {
    numeric: Boolean
  },
  computed: {
    width(): number {
      const min = 120;
      const max = 200;
      return Math.floor(Math.random() * (max - min + 1) + min) / (this.numeric ? 2 : 1);
    },
    posX(): number {
      if(!this.numeric) return 0;
      return 300 - this.width;
    }
  }
});
