import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ba03665"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "material-icons-round"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["md-avatar", [ _ctx.variant, _ctx.size ]])
  }, [
    (_ctx.variant == 'monogram')
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.initials), 1))
      : _createCommentVNode("", true),
    (_ctx.variant == 'icon')
      ? (_openBlock(), _createElementBlock("i", _hoisted_2, _toDisplayString(_ctx.content), 1))
      : _createCommentVNode("", true),
    (_ctx.variant == 'image')
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "image",
          style: _normalizeStyle({ backgroundImage: 'url(' + _ctx.content + ')' })
        }, null, 4))
      : _createCommentVNode("", true)
  ], 2))
}