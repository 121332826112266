import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59525175"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "buttons" }
const _hoisted_2 = {
  key: 0,
  class: "tabs-wrapper"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "material-icons-outlined"
}
const _hoisted_5 = {
  key: 0,
  class: "md-tabs-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_button = _resolveComponent("md-button")!
  const _component_user_profile = _resolveComponent("user-profile")!
  const _component_transition_fade = _resolveComponent("transition-fade")!
  const _component_transition_slide = _resolveComponent("transition-slide")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["md-tabs-header", [ _ctx.titleOnly ? 'title-only' : '' ]])
    }, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1),
      _createElementVNode("h3", {
        class: _normalizeClass({ visible: _ctx.scroll })
      }, _toDisplayString(_ctx.title), 3),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_md_button, {
          variant: "icon",
          icon: "account_circle",
          grey: "",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showUserProfile = !_ctx.showUserProfile))
        })
      ]),
      _createVNode(_component_transition_fade, null, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_user_profile, null, null, 512), [
            [_vShow, _ctx.showUserProfile]
          ])
        ]),
        _: 1
      }),
      (_ctx.tabs)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["md-tab-item", { active: tab.name == _ctx.$route.name, icon: !!tab.icon }]),
                key: tab.title,
                onClick: ($event: any) => (_ctx.tabClick(tab))
              }, [
                _createElementVNode("span", null, [
                  _createTextVNode(_toDisplayString(tab.title) + " ", 1),
                  (tab.icon)
                    ? (_openBlock(), _createElementBlock("i", _hoisted_4, _toDisplayString(tab.icon), 1))
                    : _createCommentVNode("", true)
                ])
              ], 10, _hoisted_3))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ], 2),
    (!_ctx.titleOnly)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_router_view, null, {
            default: _withCtx(({ Component }) => [
              _createVNode(_component_transition_slide, { direction: _ctx.transitionDirection }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                ]),
                _: 2
              }, 1032, ["direction"])
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}