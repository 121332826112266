
import { defineComponent, PropType, ref, provide, VNode } from "vue";
import { MdButton } from "@/components/md/MdButton";
import { UserProfile } from "@/components/cc/TopNav";
import { TransitionFade, TransitionSlide } from "@/components/cc/Transition";

interface MdTabProps {
  title: string;
  icon?: string;
  name: string;
}
export default defineComponent({
  name: "MdTabs",
  components: {
    MdButton,
    TransitionFade,
    TransitionSlide,
    UserProfile
  },
  props: {
    color: {
      type: String as PropType<
        "primary" | "secondary" | "tertiary" | "surface"
      >,
      required: false,
      default: () => {
        return "primary";
      },
    },
    title: String,
    titleOnly: Boolean
  },
  setup(props, { slots }) {
    const defaultSlot = slots.default;
    if(defaultSlot) {
      const tabTitles = ref(defaultSlot().map((tab: VNode) => (tab.props as MdTabProps).title));
      const tabs = ref([] as MdTabProps[]);
      const tabValues = ref([] as string[]);
      ref(defaultSlot().forEach((tab: VNode) => {
        const props = tab.props as MdTabProps;
        tabs.value.push(props);
        tabValues.value.push(props.name);
      }));
      const selectedTitle = ref(tabTitles.value[0]);
      provide("selectedTitle", selectedTitle);
      return {
        tabTitles,
        tabs,
        tabValues,
        selectedTitle
      }
    } else {
      return {
        tabs: null
      }
    }
  },
  data() {
    return {
      scroll: false,
      showUserProfile: false,
      transitionDirection: "left"
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("click", this.handleClick);
  },
  mounted() {
    this.handleScroll();
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("click", this.handleClick);
  },
  methods: {
    tabClick(tab: MdTabProps) {
      if(tab.name) {
        const fromIndex = this.tabValues?.findIndex(name => name == this.$route.name) || 0;
        const toIndex = this.tabValues?.findIndex(name => name == tab.name) || 1;
        this.transitionDirection = fromIndex >= toIndex ? "left" : "right";
        this.$router.push({
          name: tab.name
        });
      }
    },
    handleScroll() {
      if(!this.titleOnly) this.scroll = window.scrollY >= 65;
    },
    handleClick() {
      if(this.showUserProfile) this.showUserProfile = false;
    }
  },
});
