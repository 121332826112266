
import { defineComponent } from "vue";
import { ContentLoader } from "vue-content-loader";
import MdAvatarMixin from "./MdAvatarMixin";

export default defineComponent({
  name: "MdTableCellLoader",
  mixins: [MdAvatarMixin],
  components: {
    ContentLoader
  },
  computed: {
    width(): number {
      if(this.size == "small") return 32;
      if(this.size == "large") return 56;
      return 40;
    },
    radius(): number {
      return this.width / 2;
    }
  }
});
