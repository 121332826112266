import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-634c4ed8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "md-overlay"
}
const _hoisted_2 = {
  key: 0,
  class: "overlay-side"
}
const _hoisted_3 = { class: "overlay-header" }
const _hoisted_4 = { class: "overlay-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_progress_indicator_circular = _resolveComponent("md-progress-indicator-circular")!
  const _component_transition_fade = _resolveComponent("transition-fade")!
  const _component_md_button = _resolveComponent("md-button")!
  const _component_transition_md_overlay = _resolveComponent("transition-md-overlay")!

  return (_openBlock(), _createBlock(_component_transition_md_overlay, null, {
    default: _withCtx(() => [
      (_ctx.visible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (!_ctx.large)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("div", {
                    class: "image",
                    style: _normalizeStyle({ backgroundImage: 'url(' + _ctx.image + ')' })
                  }, null, 4)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_transition_fade, null, {
              default: _withCtx(() => [
                (_ctx.loading)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["overlay-loader", { large: _ctx.large }])
                    }, [
                      _createVNode(_component_md_progress_indicator_circular, { size: "large" })
                    ], 2))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createElementVNode("div", {
              class: _normalizeClass(["overlay-content", { large: _ctx.large }])
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_md_button, {
                  variant: "icon",
                  grey: "",
                  icon: "close",
                  onClick: _ctx.close
                }, null, 8, ["onClick"]),
                _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ])
            ], 2)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}