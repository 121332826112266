
import { defineComponent, inject } from "vue";

export default defineComponent({
  name: "MdTab",
  props: {
    title: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    icon: String
  },
  setup() {
    const selectedName = inject("selectedName");

    return {
      selectedName
    }
  }
});
