
import { defineComponent, PropType, VNode } from "vue";
import { IRow } from "./index";
import MdButton from "../MdButton/MdButton.vue";
import { MdCheckbox } from "../MdCheckbox";
import { MdBadge } from "../MdBadge";
import { ColumnTypes } from "./types";
import { MdMenu, TransitionMdMenu } from "@/components/md/MdMenu";
import { TransitionFade } from "@/components/cc/Transition";
import { MdTooltip } from "@/components/md/MdTooltip";
import { MdAvatar, MdAvatarLoader } from "../MdAvatar";
import { MdProgressIndicatorLinear, TransitionMdProgressIndicatorLinear } from "@/components/md/MdProgressIndicator";
import CellLoaderRaw from "./CellLoaderRaw.vue";
import CellLoaderBox from "./CellLoaderBox.vue";

interface MdTableColumnProps {
  title: string;
  display: string;
  numeric: boolean;
  type: ColumnTypes;
  width?: number;
}
interface IRowOptions {
  menuVisible: boolean;
  hovered: boolean;
  selected: boolean;
}
export default defineComponent({
  name: "MdTable",
  components: {
    MdCheckbox,
    MdButton,
    MdMenu,
    TransitionMdMenu,
    TransitionFade,
    MdTooltip,
    MdAvatar,
    MdAvatarLoader,
    MdProgressIndicatorLinear,
    TransitionMdProgressIndicatorLinear,
    CellLoaderRaw,
    CellLoaderBox,
    MdBadge
  },
  props: {
    rows: {
      type: Object as PropType<IRow[]>,
      required: true
    },
    selectable: Boolean,
    clickable: Boolean,
    header: {
      type: Boolean,
      default: true
    },
    skeletonRows: {
      type: Number,
      default: 7
    }
  },
  emits: ["click", "buttonClick", "menuItemClick", "update:rows"],
  setup(props, { slots }) {
    const defaultSlot = slots.default;
    let columns: MdTableColumnProps[] = [];
    if(defaultSlot){
      columns = defaultSlot().map((column: VNode) => (column.props as MdTableColumnProps));
      columns.forEach(column => {
        // Set default type if not set
        if(!column.type) column.type = "raw";
      });
    }
    return {
      columns
    }
  },
  created() {
    this.updateRowOptions(this.rows);
    window.addEventListener("click", this.handleDocumentClick);
  },
  data() {
    return {
      headCheckbox: false,
      checkboxes: [],
      rowOptions: [] as IRowOptions[],
      menuOpenButtonClicked: false
    }
  },
  watch: {
    headCheckbox(newVal) {
      const rows = this.rows;
      rows.forEach(row => row.selected = newVal);
      this.$emit("update:rows", rows)
    },
    rows(rows) {
      this.updateRowOptions(rows);
    }
  },
  methods: {
    rowClick(row: IRow, index: number) {
      this.$emit("click", row, index);
    },
    updateRowOptions(rows: IRow[]) {
      this.resetRowOptions(rows);
    },
    resetRowOptions(rows: IRow[]) {
      this.rowOptions = [] as IRowOptions[];
      rows.forEach(row => {
        this.rowOptions.push({
          menuVisible: false,
          hovered: false,
          selected: false
        });
      });
    },
    openMenu(rowIndex: number) {
      this.menuOpenButtonClicked = true;
      this.rowOptions[rowIndex].menuVisible = true;
      setTimeout(() => {
        this.menuOpenButtonClicked = false;
      }, 100);
    },
    handleDocumentClick() {
      if(!this.menuOpenButtonClicked) this.resetRowOptions(this.rows);
    },
    buttonClick(columnName: string, row: IRow, index: number) {
      this.$emit("buttonClick", columnName, row, index);
    }
  },
  unmounted() {
    window.removeEventListener("click", this.handleDocumentClick);
  },
});
