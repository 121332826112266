
import { defineComponent, inject, PropType } from "vue";
import { ColumnTypes } from "./types";

export default defineComponent({
  name: "MdTableColumn",
  props: {
    title: {
      type: String,
      required: true
    },
    display: {
      type: String,
      required: true
    },
    type: {
      type: String as PropType<ColumnTypes>,
      default: () => {
        return "raw";
      }
    },
    numeric: Boolean,
    width: Number,
    html: {
      type: Boolean,
      default: false
    }
  }
});
