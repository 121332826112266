
import { defineComponent, PropType } from "vue";
import MdProgressIndicatorMixin from "./MdProgressIndicatorMixin";

export default defineComponent({
  name: "MdProgressIndicatorCircular",
  mixins: [MdProgressIndicatorMixin],
  props: {
    size: {
      type: String as PropType<"small" | "medium" | "large">,
      default: "medium"
    }
  },
});
