
import { defineComponent, PropType } from "vue";
import { TransitionFade } from "@/components/cc/Transition";
import { MdProgressIndicatorCircular } from "../MdProgressIndicator";

export default defineComponent({
  name: "MdModal",
  components: {
    TransitionFade,
    MdProgressIndicatorCircular
  },
  props: {
    size: {
      type: String as PropType<"large" | "medium" | "small">,
      default: "medium",
    },
    variant: {
      type: String as PropType<"default" | "delete">,
      default: "default",
    },
    icon: String,
    title: String,
    noDivider: Boolean,
    loading: Boolean
  },
  data() {
    return {
      visibile: false,
      showLoader: this.loading
    }
  },
  watch: {
    loading(value) {
      this.showLoader = value;
    }
  },
  computed: {
    hasBody() {
      return !!this.$slots.body;
    },
  },
  emits: ["submit"],
  created() {
    window.addEventListener("keydown", this.handleKeyDownEvent);
  },
  unmounted() {
    window.removeEventListener("keydown", this.handleKeyDownEvent);
  },
  methods: {
    open() {
      this.visibile = true;
      document.documentElement.style.overflow = "hidden";
    },
    close() {
      this.visibile = false;
      document.documentElement.style.overflow = "auto";
    },
    /**
     * @param state true = display loader, false = no loader
     */
    setLoadingState(state: boolean) {
      this.showLoader = state;
    },
    handleKeyDownEvent(event: KeyboardEvent) {
      if(this.visibile){
        switch (event.key) {
          case "Escape":
            this.close();
            break;
          case "Enter":
            this.$emit("submit");
            break;
        }
      }
    }
  },
});
