import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06f71494"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "md-tabs-header" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 0,
  class: "material-icons-outlined"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.tabs)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["tabs-wrapper", [ _ctx.align ]])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["md-tab-item", { active: tab.name == _ctx.selectedName, icon: !!tab.icon, center: _ctx.align == 'center', right: _ctx.align == 'right' }]),
                key: tab.title,
                onClick: ($event: any) => (_ctx.tabClick(tab))
              }, [
                _createElementVNode("span", null, [
                  _createTextVNode(_toDisplayString(tab.title) + " ", 1),
                  (tab.icon)
                    ? (_openBlock(), _createElementBlock("i", _hoisted_3, _toDisplayString(tab.icon), 1))
                    : _createCommentVNode("", true)
                ])
              ], 10, _hoisted_2))
            }), 128))
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["md-tabs-content", [ _ctx.mode ]])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2)
  ], 64))
}