import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d1a2233c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "md-table-wrapper" }
const _hoisted_2 = { class: "md-table" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "checkbox"
}
const _hoisted_5 = {
  key: 1,
  class: "avatar-col"
}
const _hoisted_6 = {
  key: 2,
  class: "menu-col"
}
const _hoisted_7 = { key: 4 }
const _hoisted_8 = ["onMouseenter", "onMouseleave"]
const _hoisted_9 = {
  key: 0,
  class: "checkbox"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "material-icons-round" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = {
  key: 3,
  class: "button-col"
}
const _hoisted_16 = {
  key: 4,
  class: "menu-col"
}
const _hoisted_17 = {
  key: 0,
  class: "checkbox"
}
const _hoisted_18 = { key: 1 }
const _hoisted_19 = {
  key: 2,
  class: "avatar-col"
}
const _hoisted_20 = {
  key: 3,
  class: "button-col"
}
const _hoisted_21 = {
  key: 4,
  class: "menu-col"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_checkbox = _resolveComponent("md-checkbox")!
  const _component_md_avatar = _resolveComponent("md-avatar")!
  const _component_md_button = _resolveComponent("md-button")!
  const _component_transition_fade = _resolveComponent("transition-fade")!
  const _component_md_tooltip = _resolveComponent("md-tooltip")!
  const _component_md_menu = _resolveComponent("md-menu")!
  const _component_transition_md_menu = _resolveComponent("transition-md-menu")!
  const _component_md_badge = _resolveComponent("md-badge")!
  const _component_md_progress_indicator_linear = _resolveComponent("md-progress-indicator-linear")!
  const _component_transition_md_progress_indicator_linear = _resolveComponent("transition-md-progress-indicator-linear")!
  const _component_cell_loader_box = _resolveComponent("cell-loader-box")!
  const _component_cell_loader_raw = _resolveComponent("cell-loader-raw")!
  const _component_md_avatar_loader = _resolveComponent("md-avatar-loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      (_ctx.header !== false)
        ? (_openBlock(), _createElementBlock("thead", _hoisted_3, [
            _createElementVNode("tr", null, [
              (_ctx.selectable)
                ? (_openBlock(), _createElementBlock("th", _hoisted_4, [
                    _createVNode(_component_md_checkbox, {
                      modelValue: _ctx.headCheckbox,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.headCheckbox) = $event))
                    }, null, 8, ["modelValue"])
                  ]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: column.display
                }, [
                  (column.type == 'raw')
                    ? (_openBlock(), _createElementBlock("th", {
                        key: 0,
                        class: _normalizeClass({ numeric: column.numeric }),
                        style: _normalizeStyle({ width: column.width + '%' })
                      }, _toDisplayString(column.title), 7))
                    : (column.type == 'avatar')
                      ? (_openBlock(), _createElementBlock("th", _hoisted_5))
                      : (column.type == 'menu')
                        ? (_openBlock(), _createElementBlock("th", _hoisted_6))
                        : (column.type == 'badge')
                          ? (_openBlock(), _createElementBlock("th", {
                              key: 3,
                              class: _normalizeClass(["badge-col", { numeric: column.numeric }])
                            }, _toDisplayString(column.title), 3))
                          : (_openBlock(), _createElementBlock("th", _hoisted_7, _toDisplayString(column.title), 1))
                ], 64))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("tbody", null, [
        (_ctx.rows && _ctx.rows.length > 0)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.rows, (row, key) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: key,
                class: _normalizeClass({ loading: _ctx.rows[key]._loading }),
                onMouseenter: ($event: any) => (_ctx.rowOptions[key].hovered = true),
                onMouseleave: ($event: any) => (_ctx.rowOptions[key].hovered = false)
              }, [
                (_ctx.selectable)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_9, [
                      _createVNode(_component_md_checkbox, {
                        modelValue: row._selected,
                        "onUpdate:modelValue": ($event: any) => ((row._selected) = $event)
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
                  return (_openBlock(), _createElementBlock(_Fragment, {
                    key: column.display
                  }, [
                    (column.type == 'raw')
                      ? (_openBlock(), _createElementBlock("td", {
                          key: 0,
                          class: _normalizeClass({ numeric: !!column.numeric, clickable: _ctx.clickable }),
                          onClick: ($event: any) => (_ctx.rowClick(row, key))
                        }, [
                          (column.html)
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 0,
                                innerHTML: row[column.display]
                              }, null, 8, _hoisted_11))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createTextVNode(_toDisplayString(row[column.display]), 1)
                              ], 64))
                        ], 10, _hoisted_10))
                      : (column.type == 'icon')
                        ? (_openBlock(), _createElementBlock("td", {
                            key: 1,
                            class: _normalizeClass({ clickable: _ctx.clickable }),
                            onClick: ($event: any) => (_ctx.rowClick(row, key))
                          }, [
                            _createElementVNode("i", _hoisted_13, _toDisplayString(row[column.display]), 1)
                          ], 10, _hoisted_12))
                        : (column.type == 'avatar')
                          ? (_openBlock(), _createElementBlock("td", {
                              key: 2,
                              class: _normalizeClass(["avatar-col", { clickable: _ctx.clickable }]),
                              onClick: ($event: any) => (_ctx.rowClick(row, key))
                            }, [
                              _createVNode(_component_md_avatar, {
                                variant: "image",
                                size: "small",
                                content: row[column.display]
                              }, null, 8, ["content"])
                            ], 10, _hoisted_14))
                          : (column.type == 'button')
                            ? (_openBlock(), _createElementBlock("td", _hoisted_15, [
                                (row[column.display] && row[column.display].tooltip)
                                  ? (_openBlock(), _createBlock(_component_md_tooltip, {
                                      key: 0,
                                      text: row[column.display].tooltip
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_transition_fade, null, {
                                          default: _withCtx(() => [
                                            (row[column.display])
                                              ? _withDirectives((_openBlock(), _createBlock(_component_md_button, {
                                                  key: 0,
                                                  variant: "icon",
                                                  icon: row[column.display].icon,
                                                  grey: "",
                                                  onClick: ($event: any) => (_ctx.buttonClick(column.display, row, key))
                                                }, null, 8, ["icon", "onClick"])), [
                                                  [_vShow, _ctx.rowOptions[key].hovered]
                                                ])
                                              : _createCommentVNode("", true)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1032, ["text"]))
                                  : (_openBlock(), _createBlock(_component_transition_fade, { key: 1 }, {
                                      default: _withCtx(() => [
                                        (row[column.display])
                                          ? _withDirectives((_openBlock(), _createBlock(_component_md_button, {
                                              key: 0,
                                              variant: "icon",
                                              icon: row[column.display].icon,
                                              grey: ""
                                            }, null, 8, ["icon"])), [
                                              [_vShow, _ctx.rowOptions[key].hovered]
                                            ])
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 2
                                    }, 1024))
                              ]))
                            : (column.type == 'menu')
                              ? (_openBlock(), _createElementBlock("td", _hoisted_16, [
                                  _createVNode(_component_transition_fade, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_md_tooltip, { text: "Weitere Optionen" }, {
                                        default: _withCtx(() => [
                                          _withDirectives(_createVNode(_component_md_button, {
                                            variant: "icon",
                                            icon: "more_vert",
                                            grey: "",
                                            onClick: ($event: any) => (_ctx.openMenu(key))
                                          }, null, 8, ["onClick"]), [
                                            [_vShow, _ctx.rowOptions[key].hovered]
                                          ])
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_transition_md_menu, { origin: "top_right" }, {
                                    default: _withCtx(() => [
                                      (_ctx.rowOptions[key].menuVisible)
                                        ? (_openBlock(), _createBlock(_component_md_menu, {
                                            key: 0,
                                            items: row[column.display],
                                            width: 160
                                          }, null, 8, ["items"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]))
                              : (column.type == 'badge')
                                ? (_openBlock(), _createElementBlock("td", {
                                    key: 5,
                                    class: _normalizeClass(["badge-col", { numeric: !!column.numeric }])
                                  }, [
                                    (row[column.display])
                                      ? (_openBlock(), _createBlock(_component_md_badge, {
                                          key: 0,
                                          icon: row[column.display].icon || false,
                                          state: row[column.display].state
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(row[column.display].text), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["icon", "state"]))
                                      : _createCommentVNode("", true)
                                  ], 2))
                                : _createCommentVNode("", true)
                  ], 64))
                }), 128)),
                _createVNode(_component_transition_md_progress_indicator_linear, null, {
                  default: _withCtx(() => [
                    (_ctx.rows[key]._loading)
                      ? (_openBlock(), _createBlock(_component_md_progress_indicator_linear, { key: 0 }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024)
              ], 42, _hoisted_8))
            }), 128))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.skeletonRows, (key) => {
              return (_openBlock(), _createElementBlock("tr", { key: key }, [
                (_ctx.selectable)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_17, [
                      _createVNode(_component_cell_loader_box)
                    ]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
                  return (_openBlock(), _createElementBlock(_Fragment, {
                    key: column.display
                  }, [
                    (column.type == 'raw')
                      ? (_openBlock(), _createElementBlock("td", {
                          key: 0,
                          class: _normalizeClass({ numeric: !!column.numeric })
                        }, [
                          _createVNode(_component_cell_loader_raw, {
                            numeric: column.numeric
                          }, null, 8, ["numeric"])
                        ], 2))
                      : (column.type == 'icon')
                        ? (_openBlock(), _createElementBlock("td", _hoisted_18, [
                            _createVNode(_component_cell_loader_box)
                          ]))
                        : (column.type == 'avatar')
                          ? (_openBlock(), _createElementBlock("td", _hoisted_19, [
                              _createVNode(_component_md_avatar_loader, { size: "small" })
                            ]))
                          : (column.type == 'button')
                            ? (_openBlock(), _createElementBlock("td", _hoisted_20))
                            : (column.type == 'menu')
                              ? (_openBlock(), _createElementBlock("td", _hoisted_21))
                              : _createCommentVNode("", true),
                    (column.type == 'badge')
                      ? (_openBlock(), _createElementBlock("td", {
                          key: 5,
                          class: _normalizeClass({ numeric: !!column.numeric })
                        }, [
                          _createVNode(_component_cell_loader_raw, {
                            numeric: column.numeric
                          }, null, 8, ["numeric"])
                        ], 2))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 128))
              ]))
            }), 128))
      ])
    ])
  ]))
}