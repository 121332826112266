
import { defineComponent, PropType } from "vue";
import { MdButton } from "@/components/md/MdButton";
import { MdProgressIndicatorCircular } from "../MdProgressIndicator";
import { TransitionFade } from "@/components/cc/Transition";
import TransitionMdOverlay from "./TransitionMdOverlay.vue";

export default defineComponent({
  name: "MdOverlay",
  components: {
    TransitionMdOverlay,
    MdButton,
    MdProgressIndicatorCircular,
    TransitionFade
  },
  props: {
    title: String,
    visible: Boolean,
    loading: Boolean,
    image: String,
    large: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:visible"],
  methods: {
    close() {
      this.$emit('update:visible', false);
    }
  },
});
