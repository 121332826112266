
import { defineComponent, PropType } from "vue";
import EmptyHintImage from "@/assets/images/empty-hint.svg";

export default defineComponent({
  name: "EmptyHint",
  props: {
    title: {
      type: String,
      required: true
    },
    description: String,
    variant: {
      type: String as PropType<"full" | "inline">,
      default: "full"
    }
  },
  data() {
    return {
      EmptyHintImage
    }
  },
});
