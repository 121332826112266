import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, Transition as _Transition, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34e0f774"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "md-modal"
}
const _hoisted_2 = {
  key: 0,
  class: "modal-backdrop"
}
const _hoisted_3 = { class: "dialog-wrapper" }
const _hoisted_4 = {
  key: 0,
  class: "modal-loader"
}
const _hoisted_5 = { class: "modal-header-wrapper" }
const _hoisted_6 = {
  key: 0,
  class: "material-icons-round modal-icon"
}
const _hoisted_7 = { class: "modal-title" }
const _hoisted_8 = { class: "modal-content-wrapper" }
const _hoisted_9 = { class: "modal-text" }
const _hoisted_10 = { class: "modal-body" }
const _hoisted_11 = { class: "modal-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_progress_indicator_circular = _resolveComponent("md-progress-indicator-circular")!
  const _component_transition_fade = _resolveComponent("transition-fade")!

  return (_openBlock(), _createBlock(_Transition, { name: "modal" }, {
    default: _withCtx(() => [
      (_ctx.visibile)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.visibile)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_3, [
              (_ctx.visibile)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["modal-dialog", [ _ctx.size, _ctx.icon ? 'icon' : '', _ctx.variant ]])
                  }, [
                    _createVNode(_component_transition_fade, null, {
                      default: _withCtx(() => [
                        (_ctx.showLoader)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                              _createVNode(_component_md_progress_indicator_circular)
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_5, [
                      (_ctx.icon)
                        ? (_openBlock(), _createElementBlock("i", _hoisted_6, _toDisplayString(_ctx.icon), 1))
                        : _createCommentVNode("", true),
                      _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.title), 1)
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("p", _hoisted_9, [
                        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                      ]),
                      (_ctx.hasBody)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(["divider", { 'no-divider': _ctx.noDivider }])
                          }, null, 2))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_10, [
                        _renderSlot(_ctx.$slots, "body", {}, undefined, true)
                      ]),
                      (_ctx.hasBody)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass(["divider", { 'no-divider': _ctx.noDivider }])
                          }, null, 2))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_11, [
                        _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
                      ])
                    ])
                  ], 2))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}