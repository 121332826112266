import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d5ca7ab"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["cc-empty-hint", [ _ctx.variant ]])
  }, [
    (_ctx.variant != 'inline')
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.EmptyHintImage
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1),
    (_ctx.description)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.description), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 2))
}